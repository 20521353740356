<template>
  <div class="official--partner-action-card">
    <Info :developer_image="partner_image" :developer_web="partner_web" :name="name" />
    <div class="action--description" v-if="description">
      {{ description }}
    </div>
  </div>
</template>

<script>
const Info = () => import('@/components/official-partner/partials/info');
export default {
  props: {
    partner_image: {
      type: String,
    },
    partner_web: {
      type: String,
    },
    name: {
      type: String,
    },
    description: {
      type: String,
    },
  },
  components: {
    Info,
  },
};
</script>

<style></style>
